<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >配置工作小组</Divider>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">选择配置工作小组</h4>
      <div>
        <RadioGroup
          v-model="selectedWorkgroupId"
          size="small"
          vertical
        >
          <Radio
            v-for="item in workgroupArray"
            :key="'ps_'+item.id"
            :label="item.id"
          >
            <span>{{item.name}}</span>
          </Radio>
        </RadioGroup>
      </div>
    </div>
    <div class="text-right">
      <Button
        type="success"
        size="small"
        :disabled="selectedTaskItemIds.length===0"
        @click="handleSubmit"
      >确认配置</Button>
    </div>
  </div>
</template>

<script>
import { getWorkgroupPage } from '@/api/os/workgroup'
import { setLaborWorkgroup } from '@/api/msp/taskitem'
export default {
  data () {
    return {
      selectedWorkgroupId: null,
      workgroupArray: []
    }
  },
  created () {
    this.getWorkgroupData()
  },
  computed: {
    selectedTaskItemIds: {
      get () {
        return this.$store.state.supplierWorkOrder.selectedTaskItemIds
      },
      set (val) {
        this.$store.commit('set_selected_task_item_ids', val)
      }
    }
  },
  methods: {
    /**
     * 获取工作小组数据
     */
    getWorkgroupData () {
      const query = {
        companyId: this.$store.getters.token.userInfo.companyId,
        pageNumber: 1,
        pageSize: 100
      }
      getWorkgroupPage(query).then(res => {
        if (res && !res.errcode) {
          if (res.list && res.list.length > 0) {
            this.workgroupArray = res.list.map(x => {
              return {
                id: x.id,
                name: x.name
              }
            })
          }
        } else {
          this.workgroupArray = []
        }
      })
    },
    handleSubmit () {
      if (!this.selectedWorkgroupId) {
        this.$Notice.warning({ desc: '请选择一个工作小组' })
        return false
      }
      setLaborWorkgroup({ taskitemIds: JSON.stringify(this.selectedTaskItemIds), workgroupId: this.selectedWorkgroupId }).then(res => {
        if (res && res.errcode === 0) {
          // 重载列表数据
          this.$store.dispatch('getSupplierTaskData')
          this.selectedTaskItemIds = []
          this.$store.commit('set_temp_selected_item', null)
          this.selectedWorkgroupId = null

          this.$Notice.success({ desc: '操作成功' })
        }
      })
    }
  }
}
</script>
