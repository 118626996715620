import request from '@/utils/requestV2'
const qs = require('qs')

// 新增运维小组
export function addWorkGroup (data) {
  return request({
    url: '/ooh-os/v1/workgroup/addworkgroup',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除运维小组
export function deleteWorkGroup (data) {
  return request({
    url: '/ooh-os/v1/workgroup/deleteworkgroup',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取运维小组
export function getWorkGroup (data) {
  return request({
    url: '/ooh-os/v1/workgroup/getworkgroup',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取运维小组类别
export function getWorkGroupType () {
  return request({
    url: '/ooh-os/v1/workgroup/getworkgrouptype',
    method: 'post'
  })
}
// 分页获取运维小组
export function getWorkgroupPage (data) {
  return request({
    url: '/ooh-os/v1/workgroup/getworkgrouppage',
    method: 'post',
    data: qs.stringify(data)
  })
}
